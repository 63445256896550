import './Impressum.css'
import { useTranslation } from 'react-i18next';



export default function Impressum() {
    const { t } = useTranslation();

    return (
        <>
            <div className='flex-container'>
                <p className='impressum-text'>
                    WORLD SYSTEM GmbH
                    <br />        <br />

                    {t('kontakt.geschäftsführer')}: Santo Sapia
                    <br />        <br />

                    Ellwanger Str.109
                    <br />        <br />



                    73441 Bopfingen
                    <br />        <br />

                    info@world-system.de
                    <br />        <br />


                    www.world-system.de
                    <br />        <br />


                    Amtsgericht Ulm HRB 661569
                    <br />        <br />


                    USt - IdNr.: DE 216021941
                    <br />        <br />
                </p>
            </div>

        </>
    )

}