import './Footer.css'
import companyLogo from '../../src/bilder/svg/instagram.svg';
import { useTranslation, Trans } from 'react-i18next';

const lngs = {
    en: { nativeName: 'En' },
    de: { nativeName: 'De' }
};



export default function Footer() {
    const { t, i18n } = useTranslation();

    return (
        <>
            <div className="footer-container bg-dark">
                <footer>
                    <div className="inner-footer">

                        <div className='lng-switch'>
                            {Object.keys(lngs).map((lng) => (
                                <button type="button" className="btn btn-secondary mrgn-lft" key={lng} style={{
                                    textShadow: i18n.resolvedLanguage === lng ? '0px 0px 10px aliceblue' : 'none',
                                    fontWeight: i18n.resolvedLanguage === lng ? 'bold' : 'normal', backgroundColor: i18n.resolvedLanguage === lng ? '#8b7e65' : 'unset'
                                }} onClick={() => i18n.changeLanguage(lng)}>
                                    {lngs[lng].nativeName}
                                </button >
                            ))}
                        </div>
                        <a className='text-glow' href='/impressum'>{t('footer.impressum')}</a>
                        <a className='text-glow' href='/datenschutz'>{t('footer.datenschutz')}</a>
                        <div className='flex-icons'>
                            <a href="mailto: info@world-system.de" type="button" className="btn btn-outline-info">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-mailbox mailbox-mrgn" viewBox="0 0 16 16">
                                    <path d="M4 4a3 3 0 0 0-3 3v6h6V7a3 3 0 0 0-3-3zm0-1h8a4 4 0 0 1 4 4v6a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V7a4 4 0 0 1 4-4zm2.646 1A3.99 3.99 0 0 1 8 7v6h7V7a3 3 0 0 0-3-3H6.646z" />
                                    <path d="M11.793 8.5H9v-1h5a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.354-.146l-.853-.854zM5 7c0 .552-.448 0-1 0s-1 .552-1 0a1 1 0 0 1 2 0z" />
                                </svg>
                            </a>
                        </div>
                    </div>
                </footer>
            </div>
        </>
    )
}